import {
  getModule,
  Module,
  VuexModule,
  MutationAction
} from "vuex-module-decorators";
import store from "@/store";
import { isSuccess } from "@/api/response";
import { GetTypeResponse } from "@/api/light-report/response";
import * as LightReport from "@/api/light-report";

const MODULE_NAME = "light-report-type/get";

/**
 * 帳票（軽量版）一覧取得API（/get-light-report-list）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  getResponse: GetTypeResponse = {} as GetTypeResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get getReportType() {
    if (this.getResponse.results) {
      return this.getResponse.results.sqlQuery || [];
    } else {
      return [];
    }
  }

  // MutationActions
  @MutationAction
  async get() {
    const getResponse = await LightReport.getLightReportType();
    return {
      getResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      getResponse: {} as GetTypeResponse
    };
  }
}

export default getModule(Get);
