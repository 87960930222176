import service from "@/api/service";
import { ShopIdRequest, ParameterRequest, DownloadRequest } from "@/api/light-report/request";
import { GetTypeResponse, GetStatusResponse, GetListResponse, RegisterResponse } from "@/api/light-report/response";
import { DownloadZIPResponse } from "@/api/response";

/**
 * 帳票（軽量版）種別取得APIをコールします。
 *
 * @return GetResponse
 */
export async function getLightReportType() {
  const response = await service.get("/get-light-report-type");
  return response.data as GetTypeResponse;
}

/**
 * 帳票作成（軽量版）ステータス確認APIをコールします。
 *
 * @param getRequest 帳票作成（軽量版）ステータス確認APIのリクエストボディ
 * @return GetResponse
 */
export async function getStatus(getRequest: ShopIdRequest) {
  const response = await service.post("/get-light-report-status", getRequest);
  return response.data as GetStatusResponse;
}

/**
 * 帳票（軽量版）一覧取得APIをコールします。
 *
 * @param getRequest 帳票（軽量版）一覧取得APIのリクエストボディ
 * @return GetResponse
 */
export async function getLightReportList(getRequest: ShopIdRequest) {
  const response = await service.post("/get-light-report-list", getRequest);
  return response.data as GetListResponse;
}

/**
 * 帳票作成（軽量版）パラメータ保存APIをコールします。
 *
 * @param getRequest 帳票作成（軽量版）パラメータ保存APIのリクエストボディ
 * @return GetResponse
 */
export async function registParameter(getRequest: ParameterRequest) {
  const response = await service.post("/regist-query-parameter", getRequest);
  return response.data as RegisterResponse;
}

/**
 * ユーザー情報ダウンロードAPIをコールします。
 *
 * @param exportRequest
 * @return DownloadZIPResponse
 */
export async function downloadLightReport(exportRequest: DownloadRequest) {
  const response = await service.postReceiveZIP("/download-light-report", exportRequest);
  return response as DownloadZIPResponse;
}