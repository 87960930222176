import { Vue, Component, Prop } from 'vue-property-decorator';
import { ExportRequest } from "@/api/user/request";
import * as UserAPI from "@/api/user";
import { saveAs } from "file-saver";
import Flash from "@/store/common/flash";

import * as LightReport from "@/api/light-report";
import { DownloadRequest } from '@/api/light-report/request';

@Component
export default class MyComponent extends Vue {
  isLoading = false;
  selectedFileName = "";

  @Prop({ type: Number, default: 0 })
  shopId!: number;

  @Prop({ type: String, default: '' })
  reportTypeName!: string;

  @Prop({ type: Array, default: () => [] })
  report!: any[];


  /**
   * ラジオボタンで選択した帳票をダウンロードするメソッド
   * @param shopId number 店舗ID
   * @param dlPathData string ダウンロードファイル名
   * @returns 
   */
  async download(shopId: number, fileName: string) {
    this.isLoading = true;
    if (!fileName) {
      this.isLoading = false;
      return;
    }
    const exportResponse = await LightReport.downloadLightReport({shopId, fileName} );
    if (exportResponse && exportResponse.statusCd !== 200) {
      await Flash.setErrorNow({
        message: exportResponse.message,
        showReloadButton: false,
        messageSuccessBasic: ''
      });
      this.isLoading = false;
      return;
    }

    const blob = new Blob([exportResponse.results.zipString], {
      type: "application/zip"
    });
    saveAs(blob, exportResponse.results.fileName);
    this.isLoading = false;
  }

}