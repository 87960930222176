import { Component, Vue } from "vue-property-decorator";
import UlContentHeader from "../../components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIDataTable from "@/components/UIDataTable.vue";
import { TableOptions } from "@/api/request";
import Flash, { ErrorAlert } from "@/store/common/flash";
import UIDatePicker from "@/components/UIDatePicker.vue";
import LightDownloadFileInfo from "../../views/customers/LightDownloadFileInfo.vue";

import UserShopGet from "@/store/user-shop/get";

import LightReportType from "@/store/light-report/getType";
import LightReportStatus from "@/store/light-report/getStatus";
import LightReportList from "@/store/light-report/getReport";
import RegistParameter from "@/store/light-report/register";
import { ShopIdRequest, ParameterRequest } from "@/api/light-report/request";
import { GetLightReportListResult } from "@/api/light-report/response";

@Component({ components: { UlContentHeader, UlBreadcrumbs, UIDataTable, UIDatePicker, LightDownloadFileInfo } })
export default class lightDownload extends Vue {
  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "ユーザー情報ダウンロード（軽量版）";
  headingSub = "Download customer information (Light)";
  breadCrumbs = [
    { text: "カスタマー", disabled: true },
    { text: "ユーザー情報ダウンロード（軽量版）", disabled: true }
  ];
  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };
  // テーブルヘッダ
  tableHeaders = [
    { text: "", value: "yearMonth", sortable: false },
    {
      label: "",
      text: "",
      value: "custom",
      sortable: false
    }
  ];
  // ------------
  // 変動値
  // ------------
  // ローディングステータス
  isLoading = false;

  // 検索入力オプション
  inputOptions = {
    shopId: null as number | null,
    queryId: null as number | null,
    startDate: null as string | null,
    endDate: null as string | null,
  };

  inputParams = { id: 1 };

  // テーブル検索オプション（UIDataTableコンポーネントに渡す）
  tableOptions = {} as TableOptions;

  view_flg = false;

  // ------------
  
  // 帳票種別取得
  get reportType(){
    return LightReportType.getReportType;
  }

  // 帳票作成ステータス取得
  get reportStatus(){
    let result = '現在作成可能';
    switch (LightReportStatus.getReportStatus) {
      case 0:
        result = '作成処理実行待ち';
        break;
      case 1:
        result = '現在作成処理中';
        break;
      case 3:
        result = '作成処理エラー';
        break;
    }
    return result;
  }
  // 店舗名取得
  get shopName(){
    if (!LightReportStatus.isSuccess || !LightReportStatus.getShopName) {
      return null;
    }
    return LightReportStatus.getShopName;
  }

  get shopItems() {
    return UserShopGet.getItems;
  }

  /**
   * テーブルに表示するアイテムリスト
   */
  get reportItem(){
    return LightReportList.getReportList;
  }

  /**
   * 検索ボタンがクリックされた際のコールバック
   */
  async searchClickCallback() {
    this.inputOptions.shopId &&
      (this.inputParams.id = this.inputOptions.shopId | 0);
    await Flash.clear();
    await this.search();
  }

  /**
   * 検索処理
   */
  async search() {
    if (!this.inputOptions.shopId) {
      return;
    }
    this.isLoading = true;

    await LightReportStatus.get({ shopId: this.inputOptions.shopId } as ShopIdRequest);
    if (!LightReportStatus.isSuccess) {
      await Flash.setErrorNow({
        message: LightReportStatus.getMessage,
        showReloadButton: true,
      } as ErrorAlert);
    }
    await LightReportList.get({ shopId: this.inputOptions.shopId } as ShopIdRequest);
    if (!LightReportList.isSuccess) {
      await Flash.setErrorNow({
        message: LightReportList.getMessage,
        showReloadButton: true,
      } as ErrorAlert);
    }

    this.isLoading = false;
    this.view_flg = true;
  }

  /**
   * createdライフサイクルフック（UIDataTableコンポーネントに渡す）
   */
  async created() {
    await UserShopGet.getMainShop();
    await LightReportType.get();
    if (!UserShopGet.isSuccess) {
      var errMessage = ''
      if (UserShopGet.getMessage == 'データが見つかりませんでした。') {
        errMessage = '権限が不足しています。\n本機能は本店の権限を保有しているアカウントのみ利用可能です。';
      }else{
        errMessage = UserShopGet.getMessage;
      }
      await Flash.setErrorNow({
        message: errMessage,
        showReloadButton: true,
      } as ErrorAlert);
    }
    if (!LightReportType.isSuccess) {
      await Flash.setErrorNow({
        message: LightReportType.getMessage,
        showReloadButton: true,
      } as ErrorAlert);
    }
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await UserShopGet.clearResponse();
    await LightReportType.clearResponse();
    await LightReportStatus.clearResponse();
    await LightReportList.clearResponse();
  }

  goToIndex() {
    this.$router.push({ name: "customers" }).then();
  }

  /**
   * 予約確定開始日のDatePickerがクリックされた際のコールバック
   *
   * @param date 予約確定開始日
   */
  changeStartCallback(date: string | null) {
    this.inputOptions.startDate = date;
  }

  /**
   * 予約確定終了日のDatePickerがクリックされた際のコールバック
   *
   * @param date 予約確定終了日
   */
  changeEndCallback(date: string | null) {
    this.inputOptions.endDate = date;
  }

  /**
   * 作成登録実行ボタンの活性化判定(未選択or登録済みはNG)
   * @return boolean
   */
  checkRegistCreationButtonEnabled() {  
    const creationFlg = (
      (this.reportStatus === '現在作成可能' || this.reportStatus === '作成処理エラー') &&
      this.inputOptions.queryId &&
      this.inputOptions.startDate &&
      this.inputOptions.endDate
    );
    
    return creationFlg;    
  }

  /**
   * パラメータ保存
   */
  async registQueryParameter(): Promise<boolean> {
    this.isLoading = true;
    await RegistParameter.register({shopId: this.inputOptions.shopId, queryId: this.inputOptions.queryId, startDate: this.inputOptions.startDate, endDate: this.inputOptions.endDate} as ParameterRequest);
    await LightReportStatus.get({ shopId: this.inputOptions.shopId } as ShopIdRequest);
    if (!RegistParameter.isSuccess) {
      await Flash.setErrorNow({
        message: RegistParameter.getMessage,
        showReloadButton: true,
      } as ErrorAlert);
    } else {
      await Flash.setSuccessNow({
        message: "作成処理を登録しました。",
        consumePath: ""
      });
    }

    this.isLoading = false;
    return RegistParameter.isSuccess;
  }

  isArrayEmpty(array: GetLightReportListResult) {
    return Array.isArray(array) && array.length === 0;
  }

}

