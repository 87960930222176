











































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { DataOptions } from "vuetify/src/components/VData/VData";
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from "@/utils/constants";
import { SORT_ORDER_ASC, SORT_ORDER_DESC, TableOptions } from "@/api/request";
import _ from "lodash";
// import videoPlayer from "vue-video-player";
// import "vue-video-player/src/custom-theme.css";
// import "video.js/dist/video-js.css";
// Vue.use(videoPlayer);

@Component
export default class UIDataTable extends Vue {
  @Prop() headers!: {}[];
  @Prop() items!: {}[];
  @Prop({ default: false }) hideDefaultHeader?: boolean;
  @Prop({ default: false }) hideDefaultFooter?: boolean;
  @Prop() totalCount!: number;
  @Prop() loading!: boolean;
  @Prop() tableOptions!: TableOptions;
  @Prop() tableChangeCallback!: (tableOptions: TableOptions) => void;
  @Prop() actionClickCallback?: (item: {}) => void;
  @Prop() movieClickCallback?: (item: {}) => void;
  @Prop() downloadClickCallback?: (item: {}) => void;
  @Prop() editClickCallback?: (item: {}) => void;
  @Prop() deleteClickCallback?: (item: {}) => void;
  @Prop() changeFixedClickCallback?: (item: {}) => void;
  @Prop() unlinkClickCallback?: (item: {}) => void;
  @Prop() sortChangeCallback?: (item: {}) => void;
  @Prop({ default: "id" }) itemKey?: string;

  // 固定値
  // 1ページで表示するテーブル件数の選択項目
  itemsPerPageOptions = [10, 20, 50, 100];

  // 変動値
  // 検索テーブルオプション
  dataOptions = {
    page: DEFAULT_PAGE as number,
    itemsPerPage: DEFAULT_ITEMS_PER_PAGE as number,
    sortBy: [] as string[],
    sortDesc: [] as boolean[],
    multiSort: false
  } as DataOptions;

  /**
   * tableOptions propsのウォッチャー
   * tableOptions props を dataOptions dataに反映させます。
   *
   * @param newOptions 変更後のTableOptions
   * @param oldOptions 変更前のTableOptions
   */
  @Watch("tableOptions", {deep: true, immediate: true})
  async watchTableOptions(newOptions: TableOptions, oldOptions: TableOptions) {
    newOptions.page && (this.dataOptions.page = newOptions.page);
    newOptions.displayCount &&
      (this.dataOptions.itemsPerPage = newOptions.displayCount);
    newOptions.sortKey && (this.dataOptions.sortBy = [newOptions.sortKey]);
    newOptions.sortOrder &&
      (this.dataOptions.sortDesc = [newOptions.sortOrder == SORT_ORDER_DESC]);
  }

  /**
   * v-data-tableのoption変更のイベントハンドラー
   * INFO: :options.syncだとdataOptionsへの反映ズレが起きていそうなので使わない
   *
   * @param options 変更後のDataOptions
   */
  async updateDataOptions(options: DataOptions) {
    const dataOptions: DataOptions = _.cloneDeep(this.dataOptions);
    dataOptions.page = options.page;
    dataOptions.itemsPerPage = options.itemsPerPage;
    dataOptions.sortBy = options.sortBy;
    dataOptions.sortDesc = options.sortDesc;
    if (_.isEqual(this.dataOptions, dataOptions)) {
      // FIXME: 同一の場合にreturnしないと同じ検索条件で複数回APIをコールすることになる
      return;
    }

    await this.tableChangeCallback(UIDataTable.createDataOptions(dataOptions));
  }

  /**
   * DataOptionsからテーブル操作リクエストを作成する
   *
   * @param dataOptions DataOptions
   * @return TableOptions
   */
  private static createDataOptions(dataOptions: DataOptions): TableOptions {
    const request: TableOptions = {
      page: dataOptions.page,
      displayCount: dataOptions.itemsPerPage
    } as TableOptions;
    if (dataOptions.sortBy.length > 0) {
      request.sortKey = dataOptions.sortBy[0];
    }
    if (dataOptions.sortDesc.length > 0) {
      request.sortOrder = dataOptions.sortDesc[0]
        ? SORT_ORDER_DESC
        : SORT_ORDER_ASC;
    }
    return request;
  }
}
