import {
  getModule,
  Module,
  VuexModule,
  MutationAction
} from "vuex-module-decorators";
import store from "@/store";
import { isSuccess } from "@/api/response";
import { ShopIdRequest } from "@/api/light-report/request";
import { GetLightReportListResult, GetListResponse } from "@/api/light-report/response";
import * as LightReport from "@/api/light-report";

const MODULE_NAME = "light-report-list/get";

/**
 * 帳票（軽量版）種別取得（/get-light-report-type）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  getResponse: GetListResponse = {} as GetListResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get getReportList() {
    if (this.getResponse.results) {
      return this.getResponse.results || { reportType: [] };
    } else {
      return { reportType: [] };
    }
  }


  // MutationActions
  @MutationAction
  async get(getRequest: ShopIdRequest) {
    const getResponse = await LightReport.getLightReportList(getRequest);
    return {
      getResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      getResponse: {} as GetListResponse
    };
  }
}

export default getModule(Get);
