import service from "@/api/service";
import { GetResponse } from "@/api/user-shop/response";

/**
 * ユーザー情報店舗情報取得APIをコールします。
 *
 * @return GetResponse
 */
export async function get() {
  const response = await service.post("/get-user-shop");
  return response.data as GetResponse;
}

/**
 * 店舗情報取得APIをコールします。(本店のみ)
 *
 * @return GetResponse
 */
export async function getMainShop() {
  const response = await service.post("/get-user-shop",{main_shop_list:1});
  return response.data as GetResponse;
}