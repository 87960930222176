import {
  getModule,
  Module,
  VuexModule,
  MutationAction
} from "vuex-module-decorators";
import store from "@/store";
import { isSuccess } from "@/api/response";
import { ParameterRequest } from "@/api/light-report/request";
import { RegisterResponse } from "@/api/light-report/response";
import * as LightReport from "@/api/light-report";

const MODULE_NAME = "light-report-parameter/register";

/**
 * 帳票（軽量版）種別取得（/regist-query-parameter）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Modify extends VuexModule {
  // state
  registerResponse: RegisterResponse = {} as RegisterResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.registerResponse);
  }

  get getMessage() {
    return this.registerResponse.message;
  }

  @MutationAction
  async register(registerRequest: ParameterRequest) {
    const registerResponse = await LightReport.registParameter(registerRequest);
    return {
      registerResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      registerResponse: {} as RegisterResponse
    };
  }
}

export default getModule(Modify);

